<template>
  <div>
    <v-card mdi-map-marker-circle :loading="loading" :disabled="loading">
      <v-card-title>
        <h4>ລາຍລະອຽດໃບບິນ</h4>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="6">
              <div class="invoice">
                ເລກບິນ: {{ invoiceDetail.invoice_number }}
              </div>
            </v-col>
            <v-col cols="6">
              <div class="invoice">
                ວັນທີຂາຍ: {{ invoiceDetail.createdDate }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <div class="invoice">
                ປະເພດການຊຳລະ:
                <span
                    :class="getPaymentTypeColor(invoiceDetail.payment_type)"
                >{{ paymentType(invoiceDetail.payment_type) }}</span
                >
              </div>
            </v-col>
            <v-col cols="6">
              <div class="invoice">
                ປະເພດຜູ້ຂາຍ:
                <span class="primary-color">{{
                    saleByType(invoiceDetail.create_by_type)
                  }}</span>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" v-if="createdByDetail.name">
              <div class="invoice">
                ຜູ້ຂາຍ:
                <span class="primary-color">{{
                    createdByDetail.name
                  }}</span>
              </div>
              <span
                  v-for="(item, index) in invoiceDetail.createByMembers"
                  :key="index"
              >
                    {{ item.name }}
                  </span>
            </v-col>
            <v-col cols="6" v-if="customerDetail.name">
              <div class="invoice">
                ຂາຍໃຫ້: {{ customerDetail.name }}
                {{ customerDetail.surname }}
                {{ customerDetail.village_name }}, ໂທ:
                {{ customerDetail.phone }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <div class="invoice">
                ສະຖານະ
                <v-chip color="success">{{
                    invoiceDetail.invoice_status
                  }}
                </v-chip>
              </div>
            </v-col>
          </v-row>
          <v-data-table
              :headers="invoiceHeader"
              :items="invoiceDetailItem"
              :item-per-page="15"
          >
            <template v-slot:item.price="{ item }">
              <div :class="getFreeStyle(item.sale_type)">
                {{ saleType(item.sale_type, item.price) }}
              </div>
            </template>
            <template slot="item.index" scope="props">
              <div>{{ props.index + 1 }}</div>
            </template>
            <template v-slot:item.action="{ item }">
              <v-icon small @click="deleteItem(item.invoiceDetailId)"> mdi-delete</v-icon>
            </template>
          </v-data-table>
          <v-row class="justify-end mt-0">
            <v-col cols="9" class="d-flex justify-end">
              <h2 class="invoice justify-end">ຈຳນວນຕຸກນ້ຳ:</h2>
            </v-col>
            <v-col class="d-flex justify-end">
              <h2 cols="3" class="invoice justify-end">
                {{ invoiceDetail.totalBottle }}
              </h2>
            </v-col>
          </v-row>
          <v-row class="justify-end mt-0">
            <v-col cols="9" class="d-flex justify-end">
              <h2 class="invoice justify-end">ລວມຄ່ານ້ຳທັງໝົດ:</h2>
            </v-col>
            <v-col cols="3" class="d-flex justify-end">
              <h2 class="invoice justify-end">
                {{ Number(invoiceDetail.subTotal).toLocaleString() }} LAK
              </h2>
            </v-col>
          </v-row>
          <v-row class="justify-end mt-0">
            <v-col cols="9" class="d-flex justify-end">
              <h2 class="invoice justify-end">ລວມຄ່າມັດຈຳ:</h2>
            </v-col>
            <v-col cols="3" class="d-flex justify-end">
              <h2 class="invoice justify-end">
                {{ Number(invoiceDetail.bonding_fees).toLocaleString() }}
                LAK
              </h2>
            </v-col>
          </v-row>
          <v-row class="justify-end mt-0">
            <v-col cols="9" class="d-flex justify-end">
              <h2 class="invoice justify-end primary-color">ສ່ວນຫຼຸດ:</h2>
            </v-col>
            <v-col cols="3" class="d-flex justify-end">
              <h2 class="invoice justify-end primary-color">
                {{ Number(invoiceDetail.discount).toLocaleString() }}
                LAK
              </h2>
            </v-col>
          </v-row>
          <v-row class="justify-end mt-0">
            <v-col cols="9" class="d-flex justify-end">
              <h3>ທັງໝົດທີ່ຕ້ອງຈ່າຍ:</h3>
            </v-col>
            <v-col cols="3" class="d-flex justify-end">
              <h3>
                {{
                  totalPay(
                      invoiceDetail.subTotal,
                      invoiceDetail.bonding_fees, invoiceDetail.discount
                  )
                }}
                LAK
              </h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-menu
                  v-model="payment_menu"
                  :close-on-content-click="true"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="payment_date"
                      label="ເລີ່ມວັນທີ"
                      readonly
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      dense
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="payment_date"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
        <br/>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              depressed
              @click="confirmPaymentStatus('cash')"
          >
            ຊຳລະເງິນສົດ
          </v-btn>
          <v-btn
              color="success"
              class="mr-2"
              depressed
              @click="confirmPaymentStatus('bank')"
          >
            ຊຳລະທະນາຄານ
          </v-btn>
          <v-btn
              color="info"
              class="mr-2"
              depressed
              @click="confirmPaymentStatus('support')"
          >
            ສະໝັບສະໜູນ
          </v-btn>
          <v-btn
              color="warning"
              class="mr-2"
              depressed
              @click="confirmPaymentStatus('debt')"
          >
            ຕິດໜີ້
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>


    <!--Delete Modal-->
    <ModalDelete>
      <template>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete()"
          >Cancel
          </v-btn
          >
          <v-btn
              color="blue darken-1"
              text
              :loading="loading"
              :disabled="loading"
              @click="deleteItemConfirm()"
          >OK
          </v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </template>
    </ModalDelete>

  </div>
</template>

<script>
export default {

  data() {
    return {
      payment_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      payment_menu: false,

      loading: false,
      TableLoading: false,
      invoiceId: "",
      invoices: [],
      invoiceDetailItem: [],
      invoiceDetail: {},
      customerDetail: {},
      createdByDetail: {},
      //Sum Variable
      deleteId: "",
      //Payment Type
      selectedPayment: "all",
      defaultValue: {
        id: "all",
        name: "ທັງໝົດ",
      },

      //Pagination
      offset: 12,
      pagination: {},
      per_page: 12,
      search: "",
      oldVal: "",
      editedIndex: -1,

      invoiceHeader: [
        {
          text: "ລຳດັບ",
          align: "start",
          sortable: false,
          value: "index",
        },
        {text: "ລາຍການ", value: "type"},
        {text: "ລາຄາ", value: "price"},
        {text: "", value: "action"},
      ],
      toast: {
        value: true,
        color: 'success',
        msg: 'Success'
      },
      toast_error: {
        value: true,
        color: 'error',
        msg: 'Something when wrong!'
      }
    };
  },
  metaInfo() {
    return {
      title: `${this.invoiceDetail.invoice_number} - ສະຫຼຸບຍອດຂາຍ`,
    }
  },
  methods: {
    routeBack() {
      this.$router.push({name: 'Finance'});
    },

    //View Invoice
    async viewInvoice() {
      this.loading = true;
      this.invoiceId = this.$route.params.id;
      await this.$store.commit("modalView_State", true);
      this.$axios
          .get("finance/report-invoice-detail/" + this.invoiceId)
          .then((res) => {
            this.invoiceDetail = res.data.invoice;
            this.customerDetail = res.data.invoice.customer;
            this.createdByDetail = res.data.invoice.createBy;
            this.invoiceDetailItem = res.data.invoiceDetails;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
            this.$store.commit("Toast_State", this.toast_error);
          });
    },

    //Confirm Payment type
    confirmPaymentStatus(status) {
      let data = {
        "invoice_id": this.invoiceId,
        "payment_type": status,
        "payment_date": this.payment_date
      }
      this.loading = true;
      this.$axios
          .post("payment/customer/invoice", data)
          .then((res) => {
            if (res.status == 200) {
              this.loading = false;
              this.$store.commit("Toast_State", this.toast);
              this.routeBack();
            }
          })
          .catch((error) => {
            this.loading = false;
            this.$store.commit("Toast_State", this.toast_error);
            if (error.response.status == 422) {
              this.$store.commit("Toast_State", this.toast_error);
            } else {
              this.$store.commit("Toast_State", this.toast_error);
            }
          });
    },

    paymentType(value) {
      if (value == "debt") return "ຕິດໜີ້";
      else if (value == "cash") return "ເງິນສົດ";
      else if (value == "bank") return "ທະນາຄານ";
      else if (value == "support") return "ສະໜັບສະໝູນ";
      else return;
    },
    getPaymentTypeColor(value) {
      if (value == "bank") return "success--text";
      else if (value == "cash") return "primary--text";
      else if (value == "debt") return "error--text";
      else if (value == "support") return "purple--text";
    },
    saleType(sale, price) {
      if (sale == "sale") return Number(price).toLocaleString();
      else if (sale == "free") return "Free";
    },
    getFreeStyle(sale) {
      if (sale == "free") return "primary-color";
    },
    totalPay(b, total, discount) {
      return Number(b + total - discount).toLocaleString();
    },
    saleByType(type) {
      if (type == "driver") {
        return "ລົດຂົນສົ່ງ";
      } else if (type == "branch") {
        return "ສາຂາ";
      }
    },

    closeDelete() {
      this.$store.commit("modalDelete_State", false);
    },
    deleteItem(id) {
      this.deleteId = id;
      this.$store.commit("modalDelete_State", true);
    },
    deleteItemConfirm() {
      this.loading = true;
      this.$admin.get('cancel-bottle-from-customer/' + this.deleteId).then(res => {
        if (res.data.success == true) {
          this.$store.commit("modalDelete_State", false);
          this.loading = false;
          this.deleteId = '';
          if (this.invoiceDetailItem.length <= 1) {
            this.routeBack();
          }
          this.viewInvoice();
        }
      }).catch((error) => {
        setTimeout(() => {
          let msg = error.response.data.msg;
          this.loading = false;
          this.$store.commit("Toast_State", msg);
          this.$store.commit("Toast_State", this.toast);
        }, 3000);
        this.$store.commit("modalDelete_State", false);
        this.loading = false;
      })
    },
  },
  created() {
    this.viewInvoice();
  },
};
</script>

<style>
.al-end {
  text-align: end !important;
}

.invoice {
  font-size: 16px;
  color: #000000;
}

.primary-color {
  color: #00c1d2;
}
</style>
